import GtmAnalytics from '@envato/gtm-analytics'

const safeJsonParse = (unknownBlob) => {
  if (!unknownBlob) return undefined

  try {
    return JSON.parse(unknownBlob)
  } catch (error) {
    if (document.body.dataset.railsEnv) {
      const errorTracker = window.Rollbar || console
      errorTracker.error('MarketClickTracking error', error)
    }
    return undefined
  }
}

/**
 * Send payload to GA4 via gtm-analytics library per user click. If the click navigates to
 * another page, e.g. click target ancestor is `a[href^=http]`, then the payload is ensured
 * to be sent before navigating to another page.
 *
 * The above behavior can be amended, by having certain HTML attribute on the target or one
 * of its ancestors, as follow:
 *
 * | HTML attribute                           | Amended behavior                                        |
 * |------------------------------------------|---------------------------------------------------------|
 * | `data-analytics-has-custom-click="true"` | aborts and cedes control to custom behavior             |
 * | `data-analytics-click-payload="..."`     | payload consumed by gtm-analytics lib, abort if invalid |
 * | `data-analytics-click-label="..."`       | enables click tracking for non-anchor elements          |
 * | `data-analytics-context="..."`           | override context of payload if absent from payload      |
 * | `data-analytics-context-detail="..."`    | override context detail of payload if absent            |
 *
 * The gtm-analytics library is at https://github.com/envato/gtm-analytics/.
 */
const marketClickTracking = (event) => {
  const hasCustomClick = event.target.closest('[data-analytics-has-custom-click="true"]')
  if (hasCustomClick) return

  const payloadElement = event.target.closest('[data-analytics-click-payload]')
  if (payloadElement) {
    const payload = safeJsonParse(payloadElement.dataset?.analyticsClickPayload)
    if (!payload) return

    payload.context ??= event.target.closest('[data-analytics-context]')?.dataset.analyticsContext
    payload.contextDetail ??= event.target.closest('[data-analytics-context-detail]')?.dataset.analyticsContextDetail

    const anchor = event.target.closest('a')
    const usingTurbo = false
    const delayNavigation = anchor?.href?.startsWith('http') && !usingTurbo
    if (delayNavigation) {
      event.preventDefault()
      payload.callback ??= () => (window.open(anchor.href, anchor.target || '_self'))
    }

    GtmAnalytics.sendAnalyticsEvent(payload)
  } else {
    GtmAnalytics.standardisedClickTracking(event)
  }
}

const addMarketClickTrackingOnce = () => {
  const marketClickTrackingSymbol = Symbol.for('marketClickTracking')
  if (window[marketClickTrackingSymbol]) return
  window[marketClickTrackingSymbol] = marketClickTracking

  document.addEventListener('click', window[marketClickTrackingSymbol])
}

addMarketClickTrackingOnce()

window.GtmMeasurements = GtmAnalytics
